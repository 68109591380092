.quiz-question {
  width: 100%;
  font-weight: 500;
  padding: 0.75rem 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: all 0.3s ease;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
    outline: none;
  }
  &:disabled {
    border-color: #CCCCCC;
    background-color: #F0F0F0;
    outline: none;
  }
}

.dynamic-width-button {
  width: fit-content;
}

.react-select-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
}

.react-select__control {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.react-select__single-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.react-select__option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.quiz-options-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  column-gap: 2rem;
  row-gap: 1rem;
  padding: 1rem;
}

.quiz-option {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  font-size: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  padding-bottom: 2rem !important;
  padding-top: 1rem !important;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  .quiz-option-input {
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;

    &:focus {
      border-color: #007bff;
      outline: none;
    }
  }

  .quiz-option-score {
    height: calc(1rem + 0.75rem * 2);
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;

    &:focus {
      border-color: #007bff;
      outline: none;
    }
  }
}
.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 30%;
  position: relative;
}

.error-message {
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  width: 100%;
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.divider-container {
  border-bottom: 2px solid #e5e7eb;
  margin-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.questions-config-container{
  padding-bottom: 60px;
}